import React from "react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import Card from "../../cards/Card";

import {Header2} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {ButtonA, ButtonContainer, ButtonLink} from "../../styled/button/ButtonComponents";

const ProductFamily = ({
                           big_title,
                           products,
                           button,
                           button_download,
                           button_style
                       }) => {

    return (
        <SectionLayoutGrid id={big_title} customBackground="middle">
            <Col>
                <Header2 color="white" marginBottom="small">{big_title}</Header2>
            </Col>
            {products.map((item, index) => {
                return (
                    <Col span={6} grid key={index}>
                        <Card
                            avatar={item.avatar.url}
                            title={item.title}
                            description={item.description}
                        />
                    </Col>
                )
            })}
            {(button || button_download) &&
            <Col grid center>
                <ButtonContainer>
                    {button && <ButtonLink to={button.link} color={button_style}>{button.name}</ButtonLink>}
                    {button_download &&
                    <ButtonA href={button_download.file.url} target="_blank" rel="noreferrer"
                             color="secondary-brand">{button_download.name}</ButtonA>}
                </ButtonContainer>
            </Col>
            }

        </SectionLayoutGrid>
    )
}

export default ProductFamily;